import axios from "axios";

const dataProvider = (apiUrl) => {
  // we need this pre-flight function, because of lifecycle issues
  const recreateAxios = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    let token;
    let headers = {};
    if (auth && auth.accessToken) {
      token = auth.accessToken;
      headers = {
        Authorization: "Bearer " + token,
        Accept: "*/*",
      };
    }

    const ax = axios.create({
      baseURL: apiUrl,
      headers: headers,
    });
    return ax;
  };

  return {
    getList: (resource) => {
      let route = "";
      let qparams = {};

      switch (resource) {
        case "devices":
          var data = {
            descending: false,
            pageInfo: {
              pageSize: 400, //
              pageNum: 0,
            },
          };
          route = "device/list";
          var defaultDomain = localStorage.getItem("domain");
          qparams = {
            domainUuid: defaultDomain, // hard-coded for now
          };

          break;
        case "domains":
          data = {
            descending: false,
            pageInfo: {
              pageSize: 400, //
              pageNum: 0,
            },
          };
          route = "domain/list";
          break;

        case "users":
          data = {
            descending: false,
            pageInfo: {
              pageSize: 400, //
              pageNum: 0,
            },
          };
          route = "user/list";
          break;
        default:
          route = "";
      }

      let url = `${apiUrl}/${route}`;

      const ax = recreateAxios();
      return ax({
        method: "POST",
        url: url,
        data: data,
        params: qparams,
      })
        .then((res) => {
          const endresult = {
            data: res.data.data,
            total: res.data.data.length,
          };
          return Promise.resolve(endresult);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOne: (resource, params) => {
      let route = "";
      switch (resource) {
        case "devices":
          route = "device/get_by_uuid";
          break;
        case "domains":
          route = "domain/get_by_uuid";
          break;
        default:
          route = "";
      }

      let url = `${apiUrl}/${route}`;

      const ax = recreateAxios();
      return ax({
        method: "GET",
        url: url,
        params: {
          uuid: params.uuid,
        },
      })
        .then((res) => {
          return Promise.resolve({ data: res.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getStatusList: (
      // ezt kell majd átcsinálni journalosra
      resource,
      deviceuuid,
      pageSize,
      pagereq,
      fromDate,
      toDate
    ) => {
      let route = "";
      switch (resource) {
        case "devices":
          route = "device/status/list";
          break;
        default:
          route = "";
      }

      let url = `${apiUrl}/${route}`;
      let data = {
        descending: true,
        pageInfo: {
          pageSize: pageSize,
          pageNum: pagereq,
        },
      };
      const ax = recreateAxios();
      return ax({
        method: "POST",
        url: url,
        data: data,
        params: {
          deviceuuid: deviceuuid,
          from: fromDate,
          to: toDate,
        },
      })
        .then((res) => {
          return Promise.resolve({ data: res.data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getJurnalList: (
      // ezt kell majd átcsinálni journalosra
      resource,
      deviceuuid,
      pageSize,
      pagereq,
      fromDate,
      toDate
    ) => {
      let route = "";
      switch (resource) {
        case "devices":
          route = "device/journal/events/list";
          break;
        default:
          route = "";
      }

      let url = `${apiUrl}/${route}`;
      let data = {
        descending: true,
        pageInfo: {
          pageSize: pageSize,
          pageNum: pagereq,
        },
      };
      const ax = recreateAxios();
      return ax({
        method: "POST",
        url: url,
        data: data,
        params: {
          deviceuuid: deviceuuid,
          from: fromDate,
          to: toDate,
        },
      })
        .then((res) => {
          return Promise.resolve({ data: res.data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getStatusListCount: (resource, params, fromDate, toDate) => {
      // ezt is át kell írni journalosra
      let route = "";
      switch (resource) {
        case "devices":
          route = "device/status/list/count";
          var url = `${apiUrl}/${route}`;

          //console.log("getStatusListCount", url, params);
          var data = {
            descending: true,
            pageInfo: {
              pageSize: 9999999999999,
              pageNum: 0,
            },
          };
          var ax = recreateAxios();
          return ax({
            method: "POST",
            url: url,
            data: data,
            params: {
              deviceuuid: params.id,
              from: fromDate,
              to: toDate,
            },
          })
            .then((res) => {
              return Promise.resolve({ data: res.data.resultCount });
            })
            .catch((err) => {
              console.log(err);
            });

        case "domains":
          route = "domain/list/count";
          url = `${apiUrl}/${route}`;

          // console.log("getStatusListCount", url, params);
          data = {
            descending: false,
          };
          ax = recreateAxios();
          return ax({
            method: "POST",
            url: url,
            data: data,
            params: {},
          })
            .then((res) => {
              return Promise.resolve({ data: res.data.resultCount });
            })
            .catch((err) => {
              console.log(err);
            });
        default:
          route = "";
      }
    },
    create: (resource, params) => {
      return new Promise((resolve, reject) => {
        const ax = recreateAxios();
        switch (resource) {
          case "devices":
            // console.log(params.data);
            var newDevice = {
              ...params.data,
              customData: "{}",
              customStatus: "{}",
              eventEnabled: true,
            };

            ax({
              method: "POST",
              url: "/device/create",
              data: newDevice,
            })
              .then(() => {
                resolve();
              })
              .catch((err) => reject(err));
            break;
          case "domains":
            var newDomain = {
              ...params.data,
              customData: "{}",
              customStatus: "{}",
              eventEnabled: true,
            };

            ax({
              method: "POST",
              url: "/domain/create",
              data: newDomain,
            })
              .then(() => {
                resolve();
              })
              .catch((err) => reject(err));
            break;

          case "users":
            //console.log(params.data);
            /* var newUser = {
              ...params.data,
              customData: "{}",
              customStatus: "{}",
              eventEnabled: true,
            }; */

            var data = JSON.stringify({
              username: params.data.name,
              password: params.data.password,
              info: params.data.info,
            });

            ax({
              method: "POST",
              url: "/user/create",
              data: data,
            })
              .then(() => {
                resolve();
              })
              .catch((err) => reject(err));
            break;
          default:
        }
      });
    },
    update: (resource, params) => {
      // console.log(params);
      return new Promise((resolve, reject) => {
        const ax = recreateAxios();
        switch (resource) {
          case "domains":
            var data = JSON.stringify({
              uuid: params.data.uuid,
              name: params.data.name,
              info: params.data.info,
            });

            ax({
              method: "patch",
              url: `${apiUrl}/domain/modify`,

              data: data,
            })
              .then(() => {
                resolve();
              })
              .catch((err) => reject(err));
            break;
          case "devices":
            data = JSON.stringify({
              uuid: params.data.uuid,
              name: params.data.name,
              physicalId: params.data.physicalId,
              domainUuid: params.data.domainUuid,
            });

            ax({
              method: "patch",
              url: `${apiUrl}/device/modify`,

              data: data,
            })
              .then(() => {
                resolve();
              })
              .catch((err) => reject(err));
            break;
        }
      });
    },

    delete: (resource, params) => {
      // console.log(params);
      return new Promise((resolve, reject) => {
        const ax = recreateAxios();
        switch (resource) {
          case "devices":
            var data = { data: [params.id] };
            ax({
              method: "POST",
              url: "/device/delete",
              data: data,
              params: { soft: false },
            })
              .then(() => {
                resolve({ data: { id: params.id } });
              })
              .catch((err) => reject(err));
            break;

          case "users":
            data = { data: [params.id] };
            /*if(params.previousData.username === 'admin'){
              return Promise.reject()
            }*/
            ax({
              method: "POST",
              url: "/user/delete",
              params: {
                soft: false,
              },
              data: data,
            })
              .then(() => {
                resolve({ id: params.id });
              })
              .catch((err) => reject(err));
            break;

          case "domains":
            data = {
              descending: false,
              pageInfo: {
                pageSize: 400, //
                pageNum: 0,
              },
            };
            var Params = {
              domainUuid: params.id,
            };

            ax({
              method: "POST",
              url: "/role/list",
              data: data,
              params: Params,
            })
              .then((res) => {
                console.log(res.data.data);
                var rolearray = res.data.data;
                var role = {
                  data: [],
                };

                rolearray.map((data) => {
                  role.data.push(data.uuid);
                });
                ax({
                  method: "POST",
                  url: "/role/delete",
                  params: {
                    soft: false,
                  },
                  data: role,
                })
                  .then(() => {
                    data = { data: [params.id] };
                    ax({
                      method: "POST",
                      url: "/domain/delete",
                      params: {
                        soft: false,
                      },
                      data: data,
                    })
                      .then(() => {
                        resolve({ data: { id: params.id } });
                      })
                      .catch((err) => reject(err));
                  })
                  .catch((err) => reject(err));
              })
              .catch((err) => reject(err));
            break;
          default:
        }
      });
    },

    //------------------------------------------------ROLES-------------------------------------------------------------------------------
    createRoleForDomain: async (domainUuid, name) => {
      const ax = recreateAxios();
      let data = {
        name: name,
        domainUuid: domainUuid,
      };
      let Params = {};
      let res = await ax({
        method: "POST",
        url: "/role/create",
        data: data,
        params: Params,
      });
      console.log(res);
    },
    getDomainRoles: async (domainUuid) => {
      const ax = recreateAxios();
      let data = {
        descending: false,
        pageInfo: {
          pageSize: 400, //
          pageNum: 0,
        },
      };
      let Params = {
        domainUuid: domainUuid,
      };
      console.log(Params);
      let res = await ax({
        method: "POST",
        url: "/role/list",
        data: data,
        params: Params,
      });
      let rolearray = res.data.data;
      return rolearray;
    },
    addDomainRole: async (domainUuid, name) => {
      const ax = recreateAxios();
      let data = {
        name: name,
        domainUuid: domainUuid,
      };
      let Params = {};
      let createdRole = await ax({
        method: "POST",
        url: "/role/create",
        data: data,
        params: Params,
      });
      console.log(createdRole.data.uuid);
      let addedPermission;
      switch (name) {
        case "domain_admin":
          console.log(name);
          data = {
            data: [
              "5ec93d77-d319-4c79-b60d-6e8bccec480b",
              "afe07da8-3319-48ea-9057-487ebe362fd1",
              "f56e682f-aa6d-4eb1-baea-27ac071a5ee8",
              "0b2c5bca-3e98-4814-8f5f-99419e4c12cc",
              "28d11b60-609d-4c42-903f-096d204eefde",
              "31181f66-a038-490b-8bd9-821936edc233",
              "de5fc199-5661-47d5-9c78-cf7c17aeb268",
              "b3ed3d10-c7c2-4911-b5e4-fdc14e4cc70b",
              "adf76f9d-936e-45f1-bd1d-6982c6d66b84",
              "c6c4bbd5-9fde-4ab5-97bb-d07df1740382",
              "05c23710-f330-48ab-ab0d-3a102f217fad",
              "e23a0dfe-e4a0-412b-806f-db962c8c4d93",
              "0ddb3c6a-2e9f-4b37-95ff-9f15b6439a2f",
              "6750caae-e528-4063-953c-36c87e83e219",
              "a97d9c9b-bdb1-418f-b617-4cd5a9255b63",
              "c02fc6f0-26c1-4097-96b1-66ee60e8576a",
              "e5cefe5f-5a67-4d7d-a8ca-eb75aa44422d",
              "e4b8a5c1-408f-42bc-a6b4-6569d313ca45",
              "dda5193f-9703-4040-b5ab-d572cb17f899",
              "62e21dc7-b949-4103-b4b9-cd8d0c55c298",
              "76265ce0-4d48-4d7b-9588-aed30a211995",
              "45f91cf2-71a9-451e-949a-eb8c101b0fd1",
              "0ba4c4e7-6368-423e-b8cd-75695d1a3ea8",
              "43feca11-5a36-41c1-a14d-54dfd521caf9",
              "6a17913d-2345-40e8-9a52-5e4b0088c5fa",
              "cd944f12-68d9-4717-84b5-3b9866bf0062",
              "228742c5-b999-4cf8-be56-557298de0e86",
              "18170b94-12ef-4bdd-8dcf-07c068045065",
              "7dbc5f82-d8fb-4f1b-8f21-fe2ba338868b",
              "2caa73d9-be0b-427b-a11d-bce0684d24f9",
              "15b23682-bad7-4cdb-8808-1b2f40a62a71",
              "233ffc7a-57e9-4601-9b6d-0762ba94a8ee",
              "50c80516-a024-40f1-9865-cc5fe3dfd863",
              "9eda41da-cdfe-45a9-b72d-8e758d8f9b8f",
              "7d2c8a02-ea60-422c-9f62-8dfa8813fd40",
              "4f2aafbe-e90a-47eb-bb6e-b31fff9996b4",
              "af867b80-bb26-4da2-9282-4ec766137c94",
              "821f6cb2-98b1-43de-a1f2-802d7e07d046",
              "ac44f55f-dc1d-4512-bc59-46d36118a2df",
              "640e5d1f-901b-4753-b770-06c4612aa53b",
              "2f909998-f122-4e8f-a202-85fb8b0d76db",
              "650d2977-2acb-4a70-94be-d3d26477d162",
              "7d2a52cc-d16c-4023-860e-e61331972548",
              "d8b01b80-54d5-4a78-8f83-88d287d733da",
              "976fb97e-7af4-4254-bbbd-0fdcae702d3e",
              "f0ab0022-42c7-45c7-8142-abe5123f2a26",
              "c93014e9-93ba-4512-8af7-54cfa8f79ba2",
              "53e84afb-79a5-4748-8826-cfbe30d8f13a",
              "1b700504-2bef-4624-939f-fb95cb36c0a4",
              "12b3ebbf-0539-41d2-9bff-f058b8b771cb",
              "d854b8f5-aa60-4075-92fb-fe7c8616b902",
              "f6ab91d9-f07d-43a0-aae5-56daf59d806e",
              "18ffbed4-c305-41ec-a136-c8830da32791",
              "50ad3688-c1cb-4ae6-b77a-710b6faeab53",
              "dc7aaa04-b1ca-447d-b202-ec22134c6ea0",
              "d89f2964-e6e2-4d70-8b0b-2eb3745e5d0d",
              "fc4d335f-b96b-40dd-850a-b5e01ac1890c",
              "cd395751-b5ca-4c94-9e6c-39bbd5b8b226",
              "eadfb126-3ae9-42e9-9f04-1e1a88605228",
              "b07dc094-dd15-4610-9dbc-b3e74a47e2bf",
              "86ce2a01-c76a-403b-b9b1-860e65333bff",
              "9a439a5a-4bff-4d50-96dd-04ecb2f1062d",
              "fa0395f2-a185-4640-b079-62fd040f3d26",
              "c79b71cf-6b60-4bbc-9625-5f77899170bb",
              "e6edfe88-44fd-410e-bb52-1d1fe67a4bb3",
              "ebbf68f4-5734-4753-b473-57accddae4f9",
              "c6513418-4f67-464a-a43c-c6658d45bd77",
              "fda8d2bf-348f-454c-813e-a36197d75b36",
              "b3707c3b-f47a-424d-87c1-bb86420f7c3e",
              "5d4cbde8-dc52-4d5f-826e-b19aae63205a",
              "83bd316e-bcda-4a03-9109-71533b635fdf",
              "5901482a-5adb-4d48-b77b-5a2e40aba129",
              "b0f90659-4872-489b-ac2d-67d227298878",
              "5be8aed9-7630-498b-8445-f20014522a75",
              "2c7a1daf-917c-4621-a7f7-0bf119118620",
              "c448a608-83da-4546-a2a7-8ba0bc05cfde",
              "a2dee96f-9602-4fe5-ba9a-4cca4f6f6002",
              "e4a838b0-60ca-44c5-9b50-3aca69fd92a2",
              "bec557dc-4bb2-4af8-a392-920af552f6eb",
              "b6ea516a-053e-419c-8967-f98ab90fe8ae",
              "b3640891-4ae0-4f1d-bc81-39bb91e040dc",
              "4966ca60-7d3d-4a76-8e56-7f6498f5d55e",
              "5abd464f-7806-49f7-bac6-a8b499de6a04",
              "bf000527-d8c8-4a7a-9e6b-5f57ceca72f9",
              "89d24ea0-ef3f-433a-8662-38df9b8f00ba",
              "1580d2ad-d6fa-4f94-a828-bc4889ff942d",
              "415558b5-f52b-4dda-9d00-9713e4ffc84e",
              "a0edddfc-ce39-4432-a488-058bbb6494a7",
              "810502cb-cc7f-4108-a593-dd15b9b86479",
              "53c2e773-87ff-4b72-8f6d-79f8f1fc6ac5",
              "81a93116-6301-431b-aa01-971b9e5b4343",
              "6634a909-83a0-4aaa-9baa-d34b559b92d3",
              "46099ba0-49a4-4eec-8097-0f1fe197e019",
              "752490f2-3ce9-440d-aac3-9d0b7bba2dc7",
              "91a55e16-0b41-4b65-8ff1-cfecc2f78fe2",
              "4eff7fcf-3c0f-4d9d-965a-82f44756c792",
              "3172ecc2-89f4-4b9e-9fbb-ed790b16650d",
              "4730d38b-ef54-4c0c-abf7-6e55deaa8983",
              "dd859235-fa87-4ca0-9155-40c09f48edb3",
              "c3eaa26c-9aae-4f69-a352-58aa295a4c0b",
              "20ece98a-0856-40d8-a423-ed8c8e7a8943",
              "9cd6a966-f7e9-43ab-941e-cb8e69e0695e",
              "fbbf590f-3758-41f4-a40f-2c2f648b79f2",
              "5c4c86e2-f428-48b6-a175-5548e74b0986",
            ],
          };
          Params = {
            roleUuid: createdRole.data.uuid,
          };
          addedPermission = await ax({
            method: "PATCH",
            url: "/role/permission/add",
            data: data,
            params: Params,
          });
          console.log(addedPermission);
          break;
        case "domain_operator":
          console.log(name);
          data = {
            data: [
              "50c80516-a024-40f1-9865-cc5fe3dfd863",
              "ac44f55f-dc1d-4512-bc59-46d36118a2df",
              "976fb97e-7af4-4254-bbbd-0fdcae702d3e",
              "2f909998-f122-4e8f-a202-85fb8b0d76db",
              "7d2a52cc-d16c-4023-860e-e61331972548",
              "9eda41da-cdfe-45a9-b72d-8e758d8f9b8f",
              "c93014e9-93ba-4512-8af7-54cfa8f79ba2",
              "f0ab0022-42c7-45c7-8142-abe5123f2a26",
              "650d2977-2acb-4a70-94be-d3d26477d162",
              "d8b01b80-54d5-4a78-8f83-88d287d733da",
              "640e5d1f-901b-4753-b770-06c4612aa53b",
              "f6ab91d9-f07d-43a0-aae5-56daf59d806e",
              "18ffbed4-c305-41ec-a136-c8830da32791",
              "50ad3688-c1cb-4ae6-b77a-710b6faeab53",
              "dc7aaa04-b1ca-447d-b202-ec22134c6ea0",
              "d89f2964-e6e2-4d70-8b0b-2eb3745e5d0d",
              "fc4d335f-b96b-40dd-850a-b5e01ac1890c",
              "cd395751-b5ca-4c94-9e6c-39bbd5b8b226",
              "eadfb126-3ae9-42e9-9f04-1e1a88605228",
              "b07dc094-dd15-4610-9dbc-b3e74a47e2bf",
              "86ce2a01-c76a-403b-b9b1-860e65333bff",
              "9a439a5a-4bff-4d50-96dd-04ecb2f1062d",
              "fa0395f2-a185-4640-b079-62fd040f3d26",
              "c79b71cf-6b60-4bbc-9625-5f77899170bb",
              "e6edfe88-44fd-410e-bb52-1d1fe67a4bb3",
              "ebbf68f4-5734-4753-b473-57accddae4f9",
              "c6513418-4f67-464a-a43c-c6658d45bd77",
              "fda8d2bf-348f-454c-813e-a36197d75b36",
              "b3707c3b-f47a-424d-87c1-bb86420f7c3e",
              "5d4cbde8-dc52-4d5f-826e-b19aae63205a",
              "83bd316e-bcda-4a03-9109-71533b635fdf",
              "5901482a-5adb-4d48-b77b-5a2e40aba129",
              "b0f90659-4872-489b-ac2d-67d227298878",
              "5be8aed9-7630-498b-8445-f20014522a75",
              "2c7a1daf-917c-4621-a7f7-0bf119118620",
              "c448a608-83da-4546-a2a7-8ba0bc05cfde",
              "a2dee96f-9602-4fe5-ba9a-4cca4f6f6002",
              "e4a838b0-60ca-44c5-9b50-3aca69fd92a2",
              "bec557dc-4bb2-4af8-a392-920af552f6eb",
              "b6ea516a-053e-419c-8967-f98ab90fe8ae",
              "b3640891-4ae0-4f1d-bc81-39bb91e040dc",
              "4966ca60-7d3d-4a76-8e56-7f6498f5d55e",
              "5abd464f-7806-49f7-bac6-a8b499de6a04",
              "bf000527-d8c8-4a7a-9e6b-5f57ceca72f9",
              "89d24ea0-ef3f-433a-8662-38df9b8f00ba",
              "1580d2ad-d6fa-4f94-a828-bc4889ff942d",
              "415558b5-f52b-4dda-9d00-9713e4ffc84e",
              "a0edddfc-ce39-4432-a488-058bbb6494a7",
              "810502cb-cc7f-4108-a593-dd15b9b86479",
              "53c2e773-87ff-4b72-8f6d-79f8f1fc6ac5",
              "81a93116-6301-431b-aa01-971b9e5b4343",
              "6634a909-83a0-4aaa-9baa-d34b559b92d3",
              "46099ba0-49a4-4eec-8097-0f1fe197e019",
              "752490f2-3ce9-440d-aac3-9d0b7bba2dc7",
              "91a55e16-0b41-4b65-8ff1-cfecc2f78fe2",
              "4eff7fcf-3c0f-4d9d-965a-82f44756c792",
              "3172ecc2-89f4-4b9e-9fbb-ed790b16650d",
              "4730d38b-ef54-4c0c-abf7-6e55deaa8983",
              "dd859235-fa87-4ca0-9155-40c09f48edb3",
              "c3eaa26c-9aae-4f69-a352-58aa295a4c0b",
              "20ece98a-0856-40d8-a423-ed8c8e7a8943",
              "9cd6a966-f7e9-43ab-941e-cb8e69e0695e",
              "fbbf590f-3758-41f4-a40f-2c2f648b79f2",
              "5c4c86e2-f428-48b6-a175-5548e74b0986",
            ],
          };
          Params = {
            roleUuid: createdRole.data.uuid,
          };
          addedPermission = await ax({
            method: "PATCH",
            url: "/role/permission/add",
            data: data,
            params: Params,
          });
          console.log(addedPermission);

          break;
        case "domain_user":
          console.log(name);
          data = {
            data: [
              "50c80516-a024-40f1-9865-cc5fe3dfd863",
              "ac44f55f-dc1d-4512-bc59-46d36118a2df",
              "976fb97e-7af4-4254-bbbd-0fdcae702d3e",
              "2f909998-f122-4e8f-a202-85fb8b0d76db",
              "7d2a52cc-d16c-4023-860e-e61331972548",
              "9eda41da-cdfe-45a9-b72d-8e758d8f9b8f",
              "c93014e9-93ba-4512-8af7-54cfa8f79ba2",
              "f0ab0022-42c7-45c7-8142-abe5123f2a26",
              "650d2977-2acb-4a70-94be-d3d26477d162",
              "d8b01b80-54d5-4a78-8f83-88d287d733da",
              "640e5d1f-901b-4753-b770-06c4612aa53b",
              "f6ab91d9-f07d-43a0-aae5-56daf59d806e",
              "86ce2a01-c76a-403b-b9b1-860e65333bff",
              "18ffbed4-c305-41ec-a136-c8830da32791",
              "c6513418-4f67-464a-a43c-c6658d45bd77",
              "fda8d2bf-348f-454c-813e-a36197d75b36",
              "b3707c3b-f47a-424d-87c1-bb86420f7c3e",
              "4966ca60-7d3d-4a76-8e56-7f6498f5d55e",
              "5abd464f-7806-49f7-bac6-a8b499de6a04",
              "bf000527-d8c8-4a7a-9e6b-5f57ceca72f9",
              "b3640891-4ae0-4f1d-bc81-39bb91e040dc",
              "5d4cbde8-dc52-4d5f-826e-b19aae63205a",
              "4eff7fcf-3c0f-4d9d-965a-82f44756c792",
              "53c2e773-87ff-4b72-8f6d-79f8f1fc6ac5",
              "810502cb-cc7f-4108-a593-dd15b9b86479",
              "81a93116-6301-431b-aa01-971b9e5b4343",
              "9cd6a966-f7e9-43ab-941e-cb8e69e0695e",
              "fbbf590f-3758-41f4-a40f-2c2f648b79f2",
              "5c4c86e2-f428-48b6-a175-5548e74b0986",
              "20ece98a-0856-40d8-a423-ed8c8e7a8943",
            ],
          };
          Params = {
            roleUuid: createdRole.data.uuid,
          };
          addedPermission = await ax({
            method: "PATCH",
            url: "/role/permission/add",
            data: data,
            params: Params,
          });
          console.log(addedPermission);

          break;

        default:
          break;
      }
    },
    deleteDomainRoles: async (domainUuid, role) => {
      const ax = recreateAxios();
      let roleParams = {
        name: role,
        domainUuid: domainUuid,
      };
      let roleData = {};
      let roleRes = await ax({
        method: "GET",
        url: "/role/get_by_name",
        data: roleData,
        params: roleParams,
      });
      console.log(roleRes.data);

      let data = {
        data: [roleRes.data.uuid],
      };
      let Params = {
        soft: true,
      };
      let res = await ax({
        method: "POST",
        url: "/role/delete",
        data: data,
        params: Params,
      });
      console.log(res);
    },


    //------------------------------------------------------USER ROLES-------------------------------------------------------
    //Felhasználó domainhez való jog adás
    addDomainToUser:async (domainUuid, role) => {
      const ax = recreateAxios();
      let roleParams = {
        name: role,
        domainUuid: domainUuid,
      };
      let roleData = {};
      let roleRes = await ax({
        method: "GET",
        url: "/role/get_by_name",
        data: roleData,
        params: roleParams,
      });
      console.log(roleRes.data);

      let data = {
        data: [roleRes.data.uuid],
      };
      let Params = {
        soft: true,
      };
      let res = await ax({
        method: "POST",
        url: "/role/delete",
        data: data,
        params: Params,
      });
      console.log(res);
    },
  };
};
export default dataProvider;
